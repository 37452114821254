import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InboxDocumentsService {
  inboxDocumentCount!: Observable<number>;
  inboxDocumentCountSource!: ReplaySubject<number>;

  constructor() {
    this.inboxDocumentCountSource = new ReplaySubject<number>(1);
    this.inboxDocumentCount = this.inboxDocumentCountSource.asObservable();
  }

  updateInboxDocumentCount(value: number) {
    this.inboxDocumentCountSource.next(value);
  }
}
